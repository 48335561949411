import React, {
    useState, useCallback, useRef, useEffect, useMemo,
} from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import { errorService } from 'global/services';

import { parseCategories, parseDishes } from 'app/utils/basketSchema';
import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import { PERIOD_IS_DISABLED_ERROR_MESSAGE } from 'app/const/basket-errors';

import {
    selectedPeriodVar,
    selectedTagsVar,
    checkoutDeliveryDateVar,
    isBasketFilledVar,
} from 'app/apollo/reaction';
import { pickPeriodReaction } from 'app/apollo/reactions/filters';

import { FETCH_DELIVERY_ADDRESS_SESSION } from 'app/graphql/network/deliveryAddress.query';
import FILTER_QUERY from 'app/graphql/network/filter';
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import { ADD_ITEMS_TO_CART_BY_PERIOD } from 'app/graphql/network/basket/basketMutations';

import { InactivePeriodDialogContainer } from 'app/components/Dialog';

const isSkipBasket = (props) => {
    const periodIsNotSelected = props.selectedFilters.selectedPeriod === '';

    return (
        periodIsNotSelected
        || !props.basketFilledStatus.isBasketFilled
        || props.match.params.deliveryId
        || props.history.location.pathname.includes('/pay')
    );
};

export function BasketQueryAdapter({
    selectedFilters,
    basketFilledStatus,
    match,
    history,
    children,
}) {
    const props = {
        selectedFilters,
        basketFilledStatus,
        match,
        history,
        children,
    };
    const basketDataRef = useRef(null);
    const [showInactivePeriodDialog, setShowInactivePeriodDialog] = useState(false);

    const selectedPeriod = useReactiveVar(selectedPeriodVar);
    const checkoutDeliveryDate = useReactiveVar(checkoutDeliveryDateVar);
    const selectedTags = useReactiveVar(selectedTagsVar);

    /* DA */
    const [addItemsToCartByPeriod] = useMutation(ADD_ITEMS_TO_CART_BY_PERIOD);
    const { data: deliveryAddressData } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION);
    const { data: filtersData } = useQuery(FILTER_QUERY, {
        context: {
            message: '&:BasketQueryAdapter',
        },
    });

    const basketQuery = useQuery(BASKET_QUERY, {
        skip: isSkipBasket(props),
        variables: {
            period: selectedPeriod,
            delivery_date: checkoutDeliveryDate,
            trial_scenario: getTrialScenarioFromUrl(),
            // clear: false,
        },
        onError: (error) => {
            if (error.message && error.message.includes(PERIOD_IS_DISABLED_ERROR_MESSAGE)) {
                const hasBasketFillingParams = parseCategories(window.location) || parseDishes(window.location);

                if (hasBasketFillingParams && filtersData.menuFilter) {
                    const nextPeriod = filtersData.menuFilter.periods.find((p) => {
                        const isVisible = p.isVisibleInFilter === 'visible';
                        return isVisible;
                    });

                    isBasketFilledVar(false);
                    selectedPeriodVar(nextPeriod.start);
                } else {
                    setShowInactivePeriodDialog(true);
                }
            } else {
                setShowInactivePeriodDialog(false);
                errorService.log({
                    text: 'basket fetching error',
                    source: 'server',
                });
            }
        },
        onCompleted() {
            setShowInactivePeriodDialog(false);
        },
        context: {
            message: 'menu:init:withBasketQuery',
        },
    });

    /* ГОВНОКОД */
    const { data } = basketQuery;

    if (data && data.cart) {
        basketDataRef.current = data.cart;
        basketQuery.cart = data.cart;
    } else {
        basketQuery.cart = basketDataRef.current;
    }

    /* HANDLERS  */
    const handleInactivePeriodDialogConfirmBasketFilling = useCallback(async () => {
        const { menuFilter: { periods } } = filtersData;

        setShowInactivePeriodDialog(false);

        const currentPeriodIndex = periods.findIndex((p) => p.start === selectedPeriod);
        const nextPeriod = periods[currentPeriodIndex + 1];

        if (!nextPeriod) {
            window.location = '/menu/';
            return;
        }

        pickPeriodReaction({ periodStart: nextPeriod.start });

        await addItemsToCartByPeriod({
            variables: {
                period: nextPeriod.start,
                fillByPeriod: selectedPeriod,
                tags: selectedTags,
            },
        });

        window.location.reload();
    }, [addItemsToCartByPeriod, filtersData, selectedPeriod, selectedTags]);

    /* SELECTROS  */
    const [latitude, longitude] = useMemo(() => {
        if (deliveryAddressData) {
            const { deliveryAddress: { address: { coordinates } } } = deliveryAddressData;
            if (!coordinates) {
                return [null, null];
            }
            return [coordinates.latitude, coordinates.longitude];
        }
        return [null, null];
    }, [deliveryAddressData]);

    /* SELECTROS  */
    useEffect(() => {
        const hasCoordinates = Boolean(latitude && longitude);
        const hasVariables = Boolean(selectedPeriod);
        if (hasCoordinates && hasVariables) {
            basketQuery.refetch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latitude, longitude, selectedPeriod]);

    return (
        <>
            {children({
                basketQuery,
            })}
            <InactivePeriodDialogContainer
                isDialogRequired={showInactivePeriodDialog}
                onConfirm={handleInactivePeriodDialogConfirmBasketFilling}
            />
        </>
    );
}

BasketQueryAdapter.propTypes = {
    selectedFilters: PropTypes.shape({
        selectedPeriodVar: PropTypes.string,
    }).isRequired,
    basketFilledStatus: PropTypes.shape({
        isBasketFilled: PropTypes.bool,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            deliveryId: PropTypes.string,
        }),
    }).isRequired,
    history: PropTypes.shape({}).isRequired,
    children: PropTypes.func.isRequired,
};

export function withBasketQuery(Child) {
    return (props) => (
        <BasketQueryAdapter {...props}>
            {({ basketQuery }) => (
                <Child {...props} basketQuery={basketQuery} />
            )}
        </BasketQueryAdapter>
    );
}
