import React, { useMemo } from 'react';
import classNames from 'classnames';

import XBigIcon from 'assets/svg/x_medium.component.svg';
import CloseIcon from 'assets/svg/closeIcon.component.svg';
import Button from 'app/components/ui/Button';
import Paragraph from 'app/components/ui/Paragraph';
import Heading from 'app/components/ui/Heading';

import { isDesktop } from 'app/utils/resolution';
import { dialogPropTypes } from './dialogPropTypes';

import './dialog.scss';

const Dialog = (
    {
        rejectSpecificHandler,
        modeList,

        onConfirm = null,
        onConfirmOne = null,
        onConfirmAll = null,
        onReject = null,
        isConfirmLoading = false,
        isCloseConfirmAction = false,

        strongText = '',
        regularText = '',
        extraText = '',
        confirmText = '',
        confirmTextMobile = '',
        confirmOneText = '',
        confirmAllText = '',
        rejectText = '',
        rejectTextMobile = '',

        notifyOnly = false,
        rejectDisabled = false,
        oneRowButtons = false,
        extraButtonPosition = 'last',
    },
) => {
    const isDesktopResolution = useMemo(
        () => isDesktop(),
        [window.innerWidth],
    );

    const currentConfirmText = useMemo(
        () => {
            if (!isDesktopResolution) {
                return confirmTextMobile || confirmText;
            }
            return confirmText;
        },
        [isDesktopResolution, confirmText, confirmTextMobile],
    );

    const currentRejectText = useMemo(
        () => {
            if (!isDesktopResolution) {
                return rejectTextMobile || rejectText;
            }
            return rejectText;
        },
        [isDesktopResolution, rejectTextMobile, rejectText],
    );

    const handleReject = notifyOnly ? onConfirm : onReject;

    const mainButtonText = confirmAllText || currentConfirmText;
    const mainButtonHandler = onConfirmAll || onConfirm;

    const extraButtonText = confirmOneText || currentRejectText;
    const extraButtonHandler = onConfirmOne || handleReject;

    const rootContainerClasses = classNames({
        dialog__root: true,
        [modeList]: modeList !== undefined,
    });

    const buttonsContainerClasses = classNames({
        'dialog__buttons-container': true,
        'one-row': oneRowButtons,
        'two-rows': !oneRowButtons,
    });

    return (
        <div
            styleName={rootContainerClasses}
        // id="touch-scrolllock-select" note: deprecated
        >
            {modeList !== 'profile_notifications' && (
                <div styleName="dialog__header">
                    <button
                        styleName="dialog__close-button"
                        onClick={isCloseConfirmAction ? onConfirm : handleReject}
                        type="button"
                        disabled={rejectDisabled}
                    >
                        <XBigIcon />
                    </button>
                </div>
            )}
            <div styleName="dialog__title-container">
                <div styleName="dialog__strong-text-container">

                    <Heading id="css-test" level="3.2" styleName="dialog__strong-text">
                        {strongText}
                    </Heading>
                    {modeList === 'profile_notifications' && (
                        <div styleName="dialog__header">
                            <button
                                styleName="dialog__close-button"
                                onClick={isCloseConfirmAction ? onConfirm : handleReject}
                                type="button"
                                disabled={rejectDisabled}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                    )}
                </div>
                <div styleName="dialog__regular-text-container">
                    <Paragraph level="2.2" className="fixed-lineheight" styleName="dialog__regular-text">
                        {regularText}
                    </Paragraph>
                </div>
            </div>

            <div styleName={buttonsContainerClasses}>
                {extraButtonPosition === 'first' && (
                    !notifyOnly && (
                        <Button colorType="secondary" onClick={extraButtonHandler}>
                            {extraButtonText}
                        </Button>
                    )
                )}
                {mainButtonHandler && (
                    <Button
                        onClick={mainButtonHandler}
                        loading={isConfirmLoading}
                        styleName="dialog__button"
                    >
                        {mainButtonText}
                    </Button>
                )}
                {extraButtonPosition === 'last' && (
                    !notifyOnly && (
                        <Button
                            colorType="secondary"
                            onClick={() => {
                                if (rejectSpecificHandler) {
                                    rejectSpecificHandler();
                                    return;
                                }
                                extraButtonHandler();
                            }}
                        >
                            {extraButtonText}
                        </Button>
                    )
                )}
            </div>

            {Boolean(extraText) && (
                <div styleName="dialog__extra-text-container">
                    <Paragraph level="4.2" styleName="dialog__extra-text" inheritColors>
                        {extraText}
                    </Paragraph>
                </div>
            )}
        </div>
    );
};

Dialog.propTypes = dialogPropTypes;

export default Dialog;
