/* eslint-disable camelcase */
import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
    useMemo,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import {
    useLazyQuery, useMutation, useQuery, useReactiveVar,
} from '@apollo/client';
import compose from 'lodash/flowRight';


import { periods as nyPeriods } from 'global/const/ny';
import isSameWeek from 'date-fns/is_same_week';
// import { extraPeriodNYStart, extraPeriodCustomStart } from 'global/const/ny';
import { analyticService } from 'global/services';

import BILLING_MUTATION from 'app/graphql/network/billing';

import client from 'app/apollo/client';
import connect from 'app/connect';

import { selectedPeriodVar } from 'app/apollo/reaction';

import { withSelectedFilters } from 'app/connect/connectToSelectedFilters';

import {
    GET_CHECKOUT_DATA,
} from 'app/graphql/client/checkout';
import { VIEW_CUSTOMIZATION_COMMENT } from 'app/graphql/network/customizationComment';
import {
    CREATE_INVOICE,
    SHIPPING,
} from 'app/graphql/network/checkout/checkoutMutations';
import { FETCH_DELIVERY_ADDRESS_SESSION } from 'app/graphql/network/deliveryAddress.query';

import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
// import { getDishesFromBasketSections } from 'app/utils/dish';

import { withDispatchOverlay, OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import { LocationContext } from 'app/containers/LocationProvider';
import { setPaymentMethodReaction } from 'app/apollo/reactions/payment';
import { setSubscriptionTypeReaction } from 'app/apollo/reactions/subscription';
import { useCashbackNewUsersABTest } from 'app/hooks';
import {
    getFullDeliveryIntervalsData, DELIVERY_ADDRESS_SESSION,
    LAST_USER_DELIVERY_ADDRESS, FULL_CHECKOUT_DELIVERY_DATES,
} from './graphql/checkoutContainer.graphql';

import {
    checkIsNeedToShowDialog,
    isAddressesInSameSubdivision,
    setSpecificSpbPaymentMethod,
} from './checkout.utils';

import Checkout from './Checkout';

import {
    TYPE_CHECKOUT_LOADING,
    TYPE_CHECKOUT_STEP_BY_STEP,
    TYPE_CHECKOUT_SINGLE_STEP,
} from './StepByStepCheckout/stepByStepCheckoutConst';
import { saveCheckoutDataReaction, setCheckoutDeliveryDateReaction } from '../../apollo/reactions/checkout';
import { CheckoutStoreContainer, CheckoutStoreContext } from './store/checkoutStore.context';

/* DIALOG HOOKS */
import { useCustomizationDialog } from './hooks/useCustomizationDialog';
import { useNoIntervalDialog } from './hooks/useNoIntervalDialog';
import { useEmptyBasketErrorDialog } from './hooks/useEmptyBasketErrorDialog';
import { useCustomContradictionCheck } from '../Basket/hooks/useCustomContradictionCheck';

// const nyDeliveryDates = [
//     '2023-12-29',
//     '2023-12-30',
//     '2023-12-31',
// ];


/* COMPONENTS */
function CheckoutTypeAdapter(props) {
    const {
        basketQuery,
        userQuery,
        children,
        deliveryAddress,
    } = props;

    const [isAddressPopupNeeded, setIsAddressPopupNeeded] = useState(false);
    const { data: deliveryAdressData } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION);

    const { checkoutState, dispatch } = useContext(CheckoutStoreContext);
    const { checkoutTypeState: { checkoutType } } = checkoutState;

    const checkAddresPopupNeeded = useCallback(() => {
        const { user } = userQuery;
        const isNewUser = !user || (user.deliveries.length === 0 && user.successDeliveriesCount === 0);
        if (isNewUser) {
            setIsAddressPopupNeeded(false);
            return;
        }


        if (deliveryAddress?.address) {
            // DEFINE NEEDED ADDRESS FOR CHECKING
            setIsAddressPopupNeeded(!deliveryAddress?.address?.flat);
        }
    }, [userQuery, deliveryAddress?.address]);

    const resolveCheckoutType = useCallback((checkoutTypeArg) => {
        const { user } = userQuery;
        const isNewUser = !user || (user.deliveries.length === 0 && user.successDeliveriesCount === 0);
        const isUserWithLastOrderedAddress = Boolean(user && user.addressBook && user.addressBook.lastOrderedAddress);

        if (checkoutTypeArg) {
            dispatch({ type: 'SET_CHECKOUT_TYPE', payload: checkoutTypeArg });
            return;
        }

        if (isNewUser || !isUserWithLastOrderedAddress) {
            dispatch({ type: 'SET_CHECKOUT_TYPE', payload: TYPE_CHECKOUT_STEP_BY_STEP });
            return;
        }

        const canUseLastOrderedAddress = isAddressesInSameSubdivision(
            user.addressBook.lastOrderedAddress,
            deliveryAdressData.deliveryAddress.address,
        );

        if (!canUseLastOrderedAddress) {
            dispatch({ type: 'SET_CHECKOUT_TYPE', payload: TYPE_CHECKOUT_STEP_BY_STEP });
            return;
        }

        dispatch({ type: 'SET_CHECKOUT_TYPE', payload: TYPE_CHECKOUT_SINGLE_STEP });
    }, [
        userQuery,
        deliveryAdressData,
        dispatch,
    ]);

    /**
     * @description Effect определяется тип checkout'а, legacy или пошаговый
     * */
    useEffect(() => {
        if (!basketQuery?.cart || userQuery.loading) return;
        if (checkoutType !== TYPE_CHECKOUT_LOADING) return;

        resolveCheckoutType();
    }, [
        basketQuery,
        userQuery,
        checkoutType,
        resolveCheckoutType,
    ]);

    useEffect(() => {
        if (checkoutType === TYPE_CHECKOUT_SINGLE_STEP) {
            analyticService.push({ eventName: 'Checkout_Start_Onepager' });
        }
    }, [checkoutType]);

    useEffect(() => {
        checkAddresPopupNeeded();
    }, [checkAddresPopupNeeded]);

    return children({
        checkoutType,
        isAddressPopupNeeded,
        // TODO: use action
        switchToSingleStepCheckout: resolveCheckoutType,
    });
}

// eslint-disable-next-line arrow-body-style
const withCheckoutType = (WrappedComponent) => (forwardedProps) => {
    return (
        <CheckoutTypeAdapter {...forwardedProps}>
            {(typeProps) => (
                <WrappedComponent
                    {...typeProps}
                    {...forwardedProps}
                />
            )}
        </CheckoutTypeAdapter>
    );
};

/* CHEKCOUT DATA CONTAINER */
const CheckoutDataAccessContainer = (props) => {
    const { userQuery, basketQuery } = props;
    const { country_subdivision: country_subdivision_from_context } = useContext(LocationContext);
    const [_subdivisionFromShipping, _setSubdivisionFromShipping] = useState(country_subdivision_from_context);

    const customizationTags = useMemo(
        () => userQuery?.user?.customizationTags || [],
        [userQuery?.user?.customizationTags],
    );
    const {
        checkForCustomizationContradiction,
        checkIsСontraditionSearchNeeded,
    } = useCustomContradictionCheck(basketQuery.cart, customizationTags);

    const isCustomisationCheckedInBasket = useMemo(
        () => sessionStorage.getItem('with_customization') !== null,
        []);

    const isCustomizationEdited = useMemo(
        () => sessionStorage.getItem('is_customization_edited') !== null,
        [],
    );

    const isСontraditionSearchNeeded = useMemo(() => checkIsСontraditionSearchNeeded(), [checkIsСontraditionSearchNeeded]);

    const isContradictionAnalizingDisabled = isCustomizationEdited || !isСontraditionSearchNeeded;

    useEffect(
        () => {
            if (userQuery.loading) return;
            if (isCustomisationCheckedInBasket) return;
            if (isCustomizationEdited) return;
            checkForCustomizationContradiction();
        },
        [
            checkForCustomizationContradiction,
            customizationTags,
            userQuery.loading,
            isCustomisationCheckedInBasket,
            isCustomizationEdited,
        ],
    );

    const selectedPeriod = useReactiveVar(selectedPeriodVar);
    const [mutateBilling] = useMutation(BILLING_MUTATION);
    const data = useCashbackNewUsersABTest();

    const { checkoutState, dispatch } = useContext(CheckoutStoreContext);
    const {
        isX3InCurrentPeriod,
        isCustomizationIgnored,
        setIsCustomizationIgnored,
    } = useCustomizationDialog(isContradictionAnalizingDisabled);

    const { closeAllOverlays } = useContext(OverlayContextDispatch);

    const { pushNoIntervalDateDialog } = useNoIntervalDialog();

    const pushBasketErrorDialog = useEmptyBasketErrorDialog();

    const [_fetchTriger, _setFetchTriger] = useState('');
    const [isAddressChanged, setIsAddressChanged] = useState(false);
    const [isShippingSetted, setIsShippingSetted] = useState(false);
    const [isPaperRecipeChanged, setIsPaperRecipeChanged] = useState(false);
    const [isTableSettingChanged, setIsTableSettingChanged] = useState(false);
    const timeIntervalsDataRef = useRef('');

    /* SELECTORS */
    const checkoutDateAndTimeState = useMemo(() => checkoutState.dateAndTimeState, [checkoutState.dateAndTimeState]);

    // FOR PAPER RECPE FROM EVERYDAY CART (CHANGES DATES TO X+3)
    const addPaperRecipeFromBasket = sessionStorage.getItem('addPaperRecipe');
    const addTableSettingFromBasket = sessionStorage.getItem('addTableSetting');

    const isPaperRecipeSelected = useMemo(
        () => (addPaperRecipeFromBasket !== null
            ? JSON.parse(addPaperRecipeFromBasket)
            : false),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isPaperRecipeChanged, addPaperRecipeFromBasket],
    );
    const isTableSettingSelected = useMemo(
        () => (addTableSettingFromBasket !== null
            ? JSON.parse(addTableSettingFromBasket)
            : false),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isTableSettingChanged, addTableSettingFromBasket],
    );
    /* OPERATIONS */
    /* Дни */
    const filtersModifiedForABTestUser = (checkoutDateAndTimeState.isNeedToIgnoreDatesFilter || isCustomisationCheckedInBasket)
        ? false
        : isX3InCurrentPeriod;

    const [
        fetchDeliveryDaysData,
        {
            data: deliveryDaysData,
        },
    ] = useLazyQuery(FULL_CHECKOUT_DELIVERY_DATES, {
        variables: {
            period: selectedPeriod,
            trial_scenario: getTrialScenarioFromUrl(),
            filters_modified: (isСontraditionSearchNeeded && !isContradictionAnalizingDisabled)
                ? filtersModifiedForABTestUser
                : isX3InCurrentPeriod
            ,
        },
        fetchPolicy: 'network-only',
        context: {
            message: 'checkout:root:CheckoutDataAccessContainer',
        },
        onCompleted: ({ deliveryDates }) => {
            const allDates = deliveryDates?.availableDates;
            const initialDeliveryDate = allDates.find((dateItem) => dateItem.enabled);
            const deliveryDate = initialDeliveryDate?.date;
            const isNeedToShowDialog = checkIsNeedToShowDialog(selectedPeriod, deliveryDate);

            setCheckoutDeliveryDateReaction({ deliveryDate });
            dispatch({
                type: 'SELECT_INITIAL_ENABLED_DELIVERY_DATE',
                payload: {
                    day: deliveryDate,
                },
            });

            dispatch({
                type: 'SET_DATE_DIALOG_FLAG',
                payload: { isNeedToShowDialog },
            });
        },
    });

    /* Интервалы */
    const [
        fetchTimeIntervalData,
        {
            data: timeIntervalsData,
            // loading: TimeIntervalsDataLoading,
        },
    ] = useLazyQuery(getFullDeliveryIntervalsData, {
        fetchPolicy: 'cache-and-network',
        context: {
            message: 'checkout:root',
        },
        onError: () => {
            // console.log(err);
        },
    });

    /* Адрес из сессии */
    const [fetchTimeIntervalWithDateData] = useLazyQuery(getFullDeliveryIntervalsData, {});

    const {
        data: deliveryAdressData,
        loading: deliveryDataLoading,
    } = useQuery(DELIVERY_ADDRESS_SESSION, {
        context: {
            message: 'checkout:root',
        },
        onError: () => { },
    });

    /* Адрес из сессии */
    const [shipping] = useMutation(SHIPPING, {
        refetchQueries: [FETCH_DELIVERY_ADDRESS_SESSION],
        onCompleted: ({ shipping: shippingData }) => {
            if (shippingData.subdivision !== _subdivisionFromShipping) {
                _setSubdivisionFromShipping(shippingData.subdivision);
                /**
             * @description set initial payment method
             */
                setPaymentMethodReaction({ paymentMethod: 'online' });
                setSubscriptionTypeReaction({ type: 'subscription' });
            }

            setIsShippingSetted(true);
            dispatch({
                type: 'SET_ADDRESS_DIALOG_FLAG',
                payload: { isNeedToShowDialog: false },
            });
            // TODO: move refetch basket here
        },
    });

    /* Юзер */
    const { data: userData } = useQuery(LAST_USER_DELIVERY_ADDRESS);

    /**
 * @description effect реагирует на смену адреса. Выполняет запрос интервалов и дат доставки
 * @see FETCH_DELIVERY_ADDRESS_SESSION-->onCompleted-->_setFetchTriger
 */
    useEffect(() => {
        if (isShippingSetted && deliveryAdressData && deliveryAdressData.deliveryAddress) {
            const { deliveryAddress: { address: { house } } } = deliveryAdressData;

            if ((house && isAddressChanged) || isPaperRecipeChanged) {
                fetchTimeIntervalData().then(() => {
                    fetchDeliveryDaysData();
                    setIsAddressChanged(false);
                    setIsPaperRecipeChanged(false);
                });
            }
        }
    }, [
        isShippingSetted, deliveryAdressData, isAddressChanged, isPaperRecipeChanged,
        fetchDeliveryDaysData, fetchTimeIntervalData,
    ]);

    /**
 * @description effect реагирует на запрос новых дат - перенос логики из onCompleted
 */
    useEffect(
        () => {
            if (deliveryAdressData) {
                const {
                    deliveryAddress: {
                        address: {
                            country_subdivision,
                            addressValue,
                        },
                    },
                } = deliveryAdressData;

                setSpecificSpbPaymentMethod({ mutateBilling, country_subdivision });
                if (_fetchTriger !== addressValue) {
                    _setFetchTriger(addressValue);
                    setIsAddressChanged(true);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deliveryAdressData],
    );
    /**
 * @description effect реагирует на запрос новых интервалов - перенос логики из onCompleted
 */
    useEffect(
        () => {
            if (!timeIntervalsData) return;

            const previousIntervals = timeIntervalsDataRef.current?.deliveryIntervals?.deliveryTimesList;
            const nextIntervals = timeIntervalsData.deliveryIntervals.deliveryTimesList;

            const prevInterval = previousIntervals?.length
                ? previousIntervals[checkoutState.dateAndTimeState.timeIndex]
                : nextIntervals[0]; // если вдруг старый интервал куда-либо пропал, то сразу используем новый

            const sameIntervalIndex = nextIntervals.findIndex((interval) => interval.id === prevInterval.id);
            const nextIndex = sameIntervalIndex === -1 ? 0 : sameIntervalIndex;
            dispatch({
                type: 'SELECT_TIME_INTERVAL_ITEM', payload: { timeIndex: nextIndex },
            });

            dispatch({
                type: 'SET_REGION_ID', payload: { regionId: timeIntervalsData?.deliveryIntervals?.region_name || '' },
            });

            const hasRegionName = Boolean(timeIntervalsData?.deliveryIntervals?.region_name);
            // const hasIntervals = timeIntervalsData?.deliveryTimesList?.length > 0;
            const hasIntervals = true;

            dispatch({
                type: 'SET_ADDRESS_DIALOG_FLAG',
                payload: { isNeedToShowDialog: !hasRegionName || !hasIntervals },
            });

            timeIntervalsDataRef.current = timeIntervalsData;
        },
        [timeIntervalsData],
    );

    /* SELECTORS */

    /** deliveryIntervals query initial data */
    const deliveryIntervals = useMemo(
        () => (timeIntervalsData?.deliveryIntervals?.deliveryTimesList || []),
        [timeIntervalsData?.deliveryIntervals?.deliveryTimesList],
    );
    /** deliveryDates query initial data */
    const availableDates = useMemo(() => {
        const periodDates = nyPeriods.map((p) => new Date(p));

        const dates = deliveryDaysData?.deliveryDates?.availableDates || [];
        return dates.filter(({ date }) => {
            const dd = new Date(date);
            return !periodDates.some((pDate) => isSameWeek(pDate, dd, { weekStartsOn: 1 }));
        });
    }, [deliveryDaysData?.deliveryDates?.availableDates]);

    /** user query initial data */
    const lastDeliveredAddress = useMemo(
        () => userData?.user?.addressBook?.lastDeliveredAddress || {},
        [userData?.user?.addressBook?.lastDeliveredAddress],
    );

    const userId = useMemo(
        () => userData?.user?.id || null,
        [userData?.user?.id],
    );

    /* RENDER */
    if (deliveryDataLoading) {
        return null;
    }

    const saveCheckoutDataHandler = (checkoutData) => {
        saveCheckoutDataReaction({ checkoutData });
    };

    /**
 * Сохраняет в стор дату доставки и фетчит интервалы доставки
 * @param {string} deliveryDate
 */
    const setDeliveryDateHandler = (deliveryDate) => {
        setCheckoutDeliveryDateReaction({ deliveryDate });
    };

    const {
        getCheckoutData: { checkoutData },
        ...otherProps
    } = props;


    return (
        <Checkout
            {...props}
            {...otherProps}
            client={client}
            checkoutData={checkoutData}
            onWillUnmount={saveCheckoutDataHandler}
            onSelectDeliveryDate={setDeliveryDateHandler}
            deliveryIntervals={deliveryIntervals}
            deliveryAddress={deliveryAdressData?.deliveryAddress}
            checkoutDispatch={dispatch}
            checkoutDateAndTimeState={checkoutDateAndTimeState}
            deliveryDates={availableDates}
            shipping={shipping}
            userId={userId}
            lastDeliveredAddress={lastDeliveredAddress}
            fetchDeliveryDaysData={fetchDeliveryDaysData}
            fetchTimeIntervalWithDateData={fetchTimeIntervalWithDateData}
            pushNoIntervalDateDialog={pushNoIntervalDateDialog}
            closeAllOverlays={closeAllOverlays}
            isCustomizationIgnored={isCustomizationIgnored}
            setIsCustomizationIgnored={setIsCustomizationIgnored}
            pushBasketErrorDialog={pushBasketErrorDialog}
            setIsAddressChanged={setIsAddressChanged}
            setIsPaperRecipeChanged={setIsPaperRecipeChanged}
        />

    );
};

const CheckoutConnectedContainer = compose(
    connect,
    withSelectedFilters,
    graphql(GET_CHECKOUT_DATA, { name: 'getCheckoutData' }),
    graphql(VIEW_CUSTOMIZATION_COMMENT, { name: 'sessionCommentQuery' }),
    graphql(CREATE_INVOICE, { name: 'createInvoice' }),

    withLocaleContext,
    withDispatchOverlay,
    withCheckoutType,
)(CheckoutDataAccessContainer);


CheckoutDataAccessContainer.propTypes = {
    getCheckoutData: PropTypes.shape({
        checkoutData: PropTypes.shape({
            day: PropTypes.string,
        }),
    }).isRequired,

    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string.isRequired,
        selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,

    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            typeOfSet: PropTypes.string.isRequired,
            sections: PropTypes.arrayOf(PropTypes.shape({
                items: PropTypes.arrayOf(PropTypes.shape({})),
            })).isRequired,
        }),
        refetch: PropTypes.func.isRequired,
    }).isRequired,

    dispatchOverlayContext: PropTypes.shape({
        pushDialogOverlay: PropTypes.func.isRequired,
        closeLastOverlays: PropTypes.func.isRequired,
    }).isRequired,
};


/* CHEKCOUT ROOT CONTAINER */
function CheckoutRootContainer(props) {
    return (
        <CheckoutStoreContainer>
            <CheckoutConnectedContainer {...props} />
        </CheckoutStoreContainer>
    );
}

export default React.memo(CheckoutRootContainer);
