import React from 'react';
import { withRouter } from 'react-router';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { Portal } from 'react-portal';
import omit from 'lodash/omit';

import { withLocaleContext } from 'app/containers/LocaleProvider';
import { withDispatchOverlay } from 'app/containers/contexts/overlay.context';

import {
    SAVE_CUSTOMIZATION_COMMENT,
    VIEW_CUSTOMIZATION_COMMENT,
    UPDATE_CUSTOMIZATION_COMMENT,
} from 'app/graphql/network/customizationComment';
import USER_QUERY from 'app/graphql/network/auth/userQuery.graphql';

import {
    IN_PROCESS,
    APPLIED,
    OK_FROM_CUSTOMIZER,
    HAS_OLD_COMMENT_ONLY,
    HAS_OLD_AND_NEW_COMMENT,
} from 'app/utils/customizationComment';
import { PAYMENT_PERIOD_RECURRENT } from 'app/utils/paymentInfo';

import { Desktop } from 'app/components/Responsive';
import CustomizationComment from 'app/components/CustomizationComment';
import CustomizationCommentSidebar from 'app/components/CustomizationComment/CustomizationCommentSidebar';


const locales = {
    ru: {
        inProcessDelivery: 'Комментарий в обработке. Уведомление о готовности меню мы пришлем в мессенджер.',
        inProcessSession: 'Комментарий принят. После оформления заказа мы адаптируем меню под ваши пожелания и сообщим в мессенджер.',
        applied: 'Комментарий применен к меню.',
        okFromCustomizer: ['Ваш комментарий учтен!', 'Вы можете проверять и менять меню и комментарий.'],
        hasOldCommentOnly: 'Все пожелания, которые вы сообщали раньше, мы помним! Здесь можно дописать новые.',
        hasOldAndNewComment: 'Комментарий в обработке. Все предыдущие пожелания мы помним! О готовности сообщим.',
    },
    en: {
        inProcessDelivery: 'Комментарий в обработке. Уведомление о готовности меню мы пришлем в мессенджер.',
        inProcessSession:
            'Your comments have been saved. We will develop your personal menu and notify you via messenger when it is ready.',
        applied: 'Your comments have been applied to the menu.',
        okFromCustomizer: ['Your comments have been applied to the menu!', 'You can customize the menu and change the comments.'],
        hasOldCommentOnly: 'Все пожелания, которые вы сообщали раньше, мы помним! Здесь можно дописать новые.',
        hasOldAndNewComment: 'Комментарий в обработке. Все предыдущие пожелания мы помним! О готовности сообщим.',
    },
};

const massUpdateDialogTexts = {
    ru: {
        strongText: 'Применить комментарий',
        regularText: 'ко всей подписке?',
        confirmAllText: 'Да',
        confirmOneText: 'Нет, не надо',
    },
    en: {
        strongText: 'Применить комментарий',
        regularText: 'ко всей подписке?',
        confirmAllText: 'Да',
        confirmOneText: 'Нет, не надо',
    },
};

const emptyCommentMassUpdateDialogTexts = {
    ru: {
        strongText: 'Убрать все исключения',
        regularText: 'для всей подписки?',
        confirmAllText: 'Да',
        confirmOneText: 'Нет, не надо',
    },
    en: {
        strongText: 'Убрать все исключения',
        regularText: 'для всей подписки?',
        confirmAllText: 'Да',
        confirmOneText: 'Нет, не надо',
    },
};

const emptyCommentValue = 'убрать все исключения';


const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';

const TIMEOUT_AFTER_COMMENT_PROCESSED = 1000; // timeout for user to see success/error loader

const MAX_CLIPPED_COMMENT_LENGTH = 250;

const clipComment = (comment) => {
    const cuttedComment = comment.slice(0, MAX_CLIPPED_COMMENT_LENGTH);
    const arrComment = cuttedComment.split(' ');
    const slicedArr = arrComment.slice(0, -1);
    const clippedCommentValue = slicedArr.join(' ');

    return clippedCommentValue;
};

const getDelivery = ({ deliveries, deliveryId }) => deliveries.find(({ id }) => id === deliveryId);


class CustomizationCommentContainer extends React.Component {
    static getDerivedStateFromProps(props) {
        const {
            match: { params: { deliveryId } },
            userQuery: { user },
        } = props;

        if (!deliveryId || !user) return null;

        const state = { deliveryId };

        const { deliveries } = user;
        const delivery = getDelivery({ deliveries, deliveryId });

        // TODO: mock data for past deliveries
        if (!delivery) {
            return {
                deliveryId,
                canEditDeliveryComment: false,
                deliveryCommentStatus: OK_FROM_CUSTOMIZER,
            };
        }

        state.deliveryComment = delivery.customizationComment.comment;
        state.deliveryCommentStatus = delivery.customizationComment.status;
        state.canEditDeliveryComment = delivery.customizationComment.is_edit_enabled;

        const isOnlyOneDelivery = delivery.deliveries_count === 1 && delivery.billing.payment_period !== PAYMENT_PERIOD_RECURRENT;
        state.isMassUpdateOnly = isOnlyOneDelivery;

        return state;
    }

    state = {
        deliveryId: null,
        deliveryComment: null,
        deliveryCommentStatus: null,
        canEditDeliveryComment: true,

        commentValue: null,
        commentSavingState: null, // loading, success, error
        subtitleCommentValue: null,
        isMassUpdate: false, // save comment for subscription/one delivery
        isMassUpdateOnly: false,
        isToolTipShown: false,
        isClippedComment: false,
        isShownFullSubtitleComment: false,
    };

    getCommentOptions() {
        const {
            deliveryId,
            deliveryComment,
            deliveryCommentStatus,
            canEditDeliveryComment,
        } = this.state;
        const {
            sessionCommentQuery,
        } = this.props;

        const {
            comment: fetchedSessionComment,
            status: fetchedSessionCommentStatus,
            is_edit_enabled: canEditSessionComment,
        } = sessionCommentQuery.viewCustomizationComment;

        const isDeliveryComment = Boolean(deliveryId);

        const deliveryCommentOptions = {
            fetchedComment: deliveryComment,
            fetchedCommentStatus: deliveryCommentStatus,
            canEditComment: canEditDeliveryComment,
        };

        const sessionCommentOptions = {
            fetchedComment: fetchedSessionComment,
            fetchedCommentStatus: fetchedSessionCommentStatus,
            canEditComment: canEditSessionComment,
        };

        return isDeliveryComment ? deliveryCommentOptions : sessionCommentOptions;
    }

    getStatusText(fetchedCommentStatus) {
        const { localeContext: { locale } } = this.props;
        const l = locales[locale];

        const {
            deliveryId,
            canEditDeliveryComment,
        } = this.state;
        const isDeliverySubtitle = Boolean(deliveryId);

        const statusTextOptions = {
            [IN_PROCESS]: isDeliverySubtitle
                ? l.inProcessDelivery
                : l.inProcessSession,
            [APPLIED]: l.applied,
            [OK_FROM_CUSTOMIZER]: canEditDeliveryComment
                ? l.okFromCustomizer.join(' ')
                : l.okFromCustomizer[0],
            [HAS_OLD_COMMENT_ONLY]: l.hasOldCommentOnly,
            [HAS_OLD_AND_NEW_COMMENT]: l.hasOldAndNewComment,
        };

        return statusTextOptions[fetchedCommentStatus];
    }

    getCommentSavingOptions() {
        const { deliveryId } = this.state;
        const {
            saveSessionCommentMutation,
            saveDeliveryCommentMutation,
            sessionCommentQuery,
            userQuery,
        } = this.props;

        const sessionCommentSavingOptions = {
            query: sessionCommentQuery,
            mutationFn: saveSessionCommentMutation,
            mutationName: 'saveCustomizationComment',
        };

        const deliveryCommentSavingOptions = {
            query: userQuery,
            mutationFn: saveDeliveryCommentMutation,
            mutationName: 'updateCustomizationComment',
        };

        const isDeliveryComment = Boolean(deliveryId);

        return isDeliveryComment ? deliveryCommentSavingOptions : sessionCommentSavingOptions;
    }

    setSubtitleCommentValue = (comment) => this.setState({ subtitleCommentValue: comment });

    resetCommentValue = () => this.setState({ commentValue: null });

    // eslint-disable-next-line react/sort-comp
    resetСommentSavingState() {
        this.setState({ commentSavingState: null });
    }

    resetMassUpdateState() {
        this.setState({ isMassUpdate: false });
    }

    onTooLongSubtitleComment = () => {
        const { subtitleCommentValue } = this.state;

        this.setState({
            subtitleCommentValue: clipComment(subtitleCommentValue),
            isClippedComment: true,
        });
    };

    onToggleShowingFullSubtitleComment = (bool) => () => {
        const { isShownFullSubtitleComment } = this.state;
        const isBoolUndefined = typeof bool === 'undefined';

        this.setState({
            isShownFullSubtitleComment: isBoolUndefined ? !isShownFullSubtitleComment : bool,
        });
    };

    onConfirmAll = (callback) => this.setState(
        { isMassUpdate: true },
        () => callback?.(),
    );

    onConfirmOne = (callback) => this.setState(
        { isMassUpdate: false },
        () => callback?.(),
    );

    onToggleToolTip = () => {
        const { isToolTipShown } = this.state;

        this.setState({ isToolTipShown: !isToolTipShown });
    };

    onCloseTooltip = () => this.setState({ isToolTipShown: false });

    onChangeComment = ({ value }) => this.setState({ commentValue: value });

    hanldeSubmitComment = () => {
        const {
            dispatchOverlayContext: { pushDialogOverlay, closeLastOverlays },
            localeContext: { locale },
        } = this.props;

        const {
            deliveryId,
            isMassUpdateOnly,
            commentValue,
        } = this.state;

        if (!deliveryId) {
            this.onSubmit();
            return;
        }

        const dialogTexts = commentValue?.trim() === ''
            ? emptyCommentMassUpdateDialogTexts
            : massUpdateDialogTexts;

        if (isMassUpdateOnly) {
            this.onConfirmAll(this.onSubmit);
        } else {
            const dialogData = {
                ...dialogTexts[locale],
                oneRowButtons: true,
                onConfirmOne: () => {
                    closeLastOverlays();
                    this.onConfirmOne(() => {
                        this.onSubmit();
                    });
                },
                onConfirmAll: () => {
                    closeLastOverlays();
                    this.onConfirmAll(() => {
                        this.onSubmit();
                    });
                },
            };

            pushDialogOverlay('comment_mass_update_dialog', dialogData);
        }
    };

    onSubmit = () => {
        const { toggleCommentArea, handleSetIsCustomizationEdited } = this.props;
        const {
            deliveryId,
            commentValue,
            isMassUpdate,
        } = this.state;

        const isEmptyCommentForDelivery = deliveryId && commentValue?.trim() === '';
        const comment = isEmptyCommentForDelivery ? emptyCommentValue : commentValue;

        const variables = {
            comment,
            delivery_id: deliveryId,
            mass_update: isMassUpdate,
        };

        const { query, mutationFn, mutationName } = this.getCommentSavingOptions();

        const callback = () => {
            this.resetCommentValue();

            setTimeout(() => {
                this.resetСommentSavingState();
                this.resetMassUpdateState();
                toggleCommentArea();
            }, TIMEOUT_AFTER_COMMENT_PROCESSED);
        };

        this.setState({ commentSavingState: LOADING }, async () => {
            try {
                const { data } = await mutationFn({ variables });
                const { [mutationName]: { error: mutationError } } = data;

                if (mutationError) {
                    this.setState({ commentSavingState: ERROR }, callback);
                } else {
                    await query.refetch();
                    this.setState({ commentSavingState: SUCCESS }, callback);
                    handleSetIsCustomizationEdited(true);
                }
            } catch (err) {
                Raven.captureException(err);
                this.setState({ commentSavingState: ERROR }, callback);
            }
        });
    };

    render() {
        const {
            sessionCommentQuery,

            isNeededBlurSidebarBackdrop,
            isCommentSidebarShown,
            isCommentAreaShown,
            toggleCommentArea,
            localeContext: { locale },
        } = this.props;

        const {
            commentValue,
            subtitleCommentValue,
            commentSavingState,
            isToolTipShown,
            isClippedComment,
            isShownFullSubtitleComment,
        } = this.state;

        const isDataFetched = !!sessionCommentQuery.viewCustomizationComment;

        if (!isDataFetched) return null;

        const { fetchedComment, fetchedCommentStatus, canEditComment } = this.getCommentOptions();
        const statusText = this.getStatusText(fetchedCommentStatus);

        const isInitialCommentValue = commentValue === null;
        const usedComment = isInitialCommentValue ? fetchedComment : commentValue;
        const finalCommentValue = usedComment?.trim() === emptyCommentValue ? '' : usedComment;
        const fetchedCommentValue = fetchedComment?.trim() === emptyCommentValue ? '' : fetchedComment;

        const commonProps = {
            isCommentSaving: commentSavingState === LOADING,
            isCommentSaved: commentSavingState === SUCCESS,
            isInitialCommentValue,
            isCommentAreaShown,
            commentValue: finalCommentValue,
            onChangeComment: this.onChangeComment,
            onSubmitComment: this.hanldeSubmitComment,
        };

        const specProps = omit(this.props, [isCommentSidebarShown, isCommentAreaShown]);

        return (
            <>
                <CustomizationComment
                    {...commonProps}
                    {...specProps}
                    subtitleCommentValue={subtitleCommentValue}
                    canEditComment={canEditComment}
                    fetchedComment={fetchedCommentValue}
                    fetchedCommentStatus={fetchedCommentStatus}
                    statusText={statusText}
                    isClippedComment={isClippedComment}
                    isToolTipShown={isToolTipShown}
                    isShownFullSubtitleComment={isShownFullSubtitleComment}
                    onSubmitComment={this.hanldeSubmitComment}
                    onSubmit={this.onSubmit}
                    onChangeComment={this.onChangeComment}
                    onToggleToolTip={this.onToggleToolTip}
                    onTooLongSubtitleComment={this.onTooLongSubtitleComment}
                    onToggleShowingFullSubtitleComment={this.onToggleShowingFullSubtitleComment()}
                    onCloseTooltip={this.onCloseTooltip}
                    onCloseCommentArea={this.resetCommentValue}
                    setSubtitleCommentValue={this.setSubtitleCommentValue}
                    collapseSubtitleComment={this.onToggleShowingFullSubtitleComment(false)}
                />

                {isCommentSidebarShown && (
                    <Desktop>
                        <Portal node={document && document.getElementById('react-portal-mount-node')}>
                            <CustomizationCommentSidebar
                                {...commonProps}
                                isNeedBlur={isNeededBlurSidebarBackdrop}
                                isSidebar
                                onClose={toggleCommentArea}
                                isOpen={isCommentAreaShown}
                                locale={locale}
                            />
                        </Portal>
                    </Desktop>
                )}
            </>
        );
    }
}

CustomizationCommentContainer.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
    dispatchOverlayContext: PropTypes.shape({
        pushDialogOverlay: PropTypes.func,
        closeLastOverlays: PropTypes.func,
    }).isRequired,

    toggleCommentArea: PropTypes.func.isRequired,
    saveSessionCommentMutation: PropTypes.func.isRequired,
    saveDeliveryCommentMutation: PropTypes.func.isRequired,
    isCommentAreaShown: PropTypes.bool.isRequired,
    isCommentSidebarShown: PropTypes.bool,

    isNeededBlurSidebarBackdrop: PropTypes.bool,

    match: PropTypes.shape({
        params: PropTypes.shape({
            deliveryId: PropTypes.string,
        }),
    }).isRequired,

    userQuery: PropTypes.shape({
        refetch: PropTypes.func.isRequired,
        user: PropTypes.shape({
            deliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        }),
    }),

    sessionCommentQuery: PropTypes.shape({
        viewCustomizationComment: PropTypes.shape({
            comment: PropTypes.string,
            status: PropTypes.number,
            is_edit_enabled: PropTypes.bool,
        }),
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        refetch: PropTypes.func.isRequired,
    }),
    handleSetIsCustomizationEdited: PropTypes.func.isRequired,
};

const CustomizationCommentContainerFC = (props) => {
    const {
        userQuery = null,
        isCommentSidebarShown = false,
        isNeededBlurSidebarBackdrop = false,
        sessionCommentQuery = {
            viewCustomizationComment: {
                comment: '',
                status: null,
                is_edit_enabled: true,
            },
        },
    } = props;

    return (
        <CustomizationCommentContainer
            {...props}
            userQuery={userQuery}
            isCommentSidebarShown={isCommentSidebarShown}
            isNeededBlurSidebarBackdrop={isNeededBlurSidebarBackdrop}
            sessionCommentQuery={sessionCommentQuery}
        />
    );
};

export default compose(
    graphql(USER_QUERY, {
        name: 'userQuery',
        options: {
            context: {
                massage: 'app:init:CustomizationCommentContainer',
            },
        },
    }),
    graphql(VIEW_CUSTOMIZATION_COMMENT, { name: 'sessionCommentQuery' }),
    graphql(SAVE_CUSTOMIZATION_COMMENT, { name: 'saveSessionCommentMutation' }),
    graphql(UPDATE_CUSTOMIZATION_COMMENT, { name: 'saveDeliveryCommentMutation' }),
    withLocaleContext,
    withDispatchOverlay,
    withRouter,
)(CustomizationCommentContainerFC);

