import React from 'react';
import classNames from 'classnames';
import { firstCards, secondCards } from './cards.record';
import css from './halloweenDetails.module.scss';
import offerBannerPath from './img/offerBanner.png';

const HelloweenOfferCompoent = () => (
    <div className={css.helloweenOfferRoot}>
        <div className="">
            <div className={css.helloweenOfferImgWrapper}>
                <img src={offerBannerPath} alt="offer" className={css.helloweenOfferImg} />
            </div>
            <div className={css.halloweenDetailsTitle}>
                Бокс на Хэллоуин 3 940 ₽
            </div>
            <div className={css.classNamehelloweenOfferContent}>
                <div className={css.helloweenOfferText}>
                    Все включено:
                </div>
                <div className={css.helloweenOfferList}>
                    <div className={css.helloweenOfferText}>
                        Продукты для 4 блюд
                        <br />
                        на 4-6 человек
                    </div>
                    <div className={css.helloweenOfferText}>
                        Тыква с инструкцией
                        для фонаря
                    </div>
                    <div className={css.helloweenOfferText}>
                        Приятные дополнения
                        <br />
                        для сервировки
                    </div>
                </div>
            </div>
            <div className={classNames(css.helloweenOfferText, css.bold)}>
                Доставка бесплатно
            </div>
        </div>
    </div>
);

const HalloweenDishCardForDetail = ({
    title,
    desc,
    img,
}) => (
    <div className={css.halloweenDishCardRoot}>
        <div className={css.halloweenDishCardImg}>
            <img src={img} alt="" className={css.halloweenDishCardImgItem} />
        </div>
        <div className={css.halloweenDishCardTitle}>{title}</div>
        <div className={css.halloweenDishCardDesc}>{desc}</div>
    </div>
);

// NOTE: Деталка Halloween
export const HalloweenDetails = (props) => {
    const { id } = props;

    const dishDetailsTitleClasses = classNames({
        [css.halloweenDetailsTitle]: true,
        [css.smallMargin]: true,
    });

    return (
        <div className={css.halloweenDetailsRoot}>
            <div className={dishDetailsTitleClasses}>
                Всё, чтобы устроить настоящую
                вечеринку на Хэллоуин
            </div>
            <div className={css.halloweenDetailsDescription}>
                Страшно вкусные блюда, паутины, летучие
                мыши и, конечно, тыквенная голова Джека!
                Готово за 1 час, хватит на 4-6 человек
            </div>
            <div className={css.halloweenDetailsTitle}>
                В меню на Хэллоуин
            </div>
            {/*  */}
            <div className={css.halloweenDetailsList}>
                {firstCards.map((itm) => <HalloweenDishCardForDetail {...itm} />)}
            </div>
            {/*  */}
            <div className={classNames(css.halloweenDetailsTitle, css.topIndent)}>
                А ещё в наборе
            </div>
            {/*  */}
            <div className={css.halloweenDetailsList}>
                {secondCards.map((itm) => <HalloweenDishCardForDetail {...itm} />)}
            </div>
            {/*  */}
            <HelloweenOfferCompoent />
        </div>
    );
};
