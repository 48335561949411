/* eslint-disable max-len */
import React, { useContext } from 'react';
import { analyticService } from 'global/services';

import { isStandaloneApp } from 'app/utils/browser';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import css from './BasketSetsSelection.module.scss';

import { BasketSetsSelectionSlider } from './BasketSetsSelectionSlider';

export type TCurrentSetType = 'hp'

interface IBasketSetsSelectionProps {
    setName: TCurrentSetType
}

export const BasketSetsSelection = (props: IBasketSetsSelectionProps) => {
    const {
        setName,
    } = props;

    const { onOpenSelection } = useContext<any>(DishesSelectionContext);


    const isApp = isStandaloneApp();
    const onClickHandler = () => {
        analyticService.push({
            eventName: 'Upsell_Click',
            upsell_type: 'promo',
            banner_name: setName,
        });
    };
    return (
        // <a href="/hp" target={!isApp ? '_blank' : undefined}>
        <button
            onClick={() => { onOpenSelection({ tag: 'hp' }); }}
            type="button"
        >
            <div
                className={css.basketSetsSelectionRootWrapper}
                onClickCapture={onClickHandler}
                role="none"
            >
                <div className={css.basketSetsSelectionRoot}>
                    <div className={css.basketSetsSelectionSliderContainer}>
                        <div className={css.basketSetsSelectionTitle}>
                            Меню из Хогвартса
                        </div>
                        <div className={css.basketSetsSelectionDescription}>
                            Переносимся во вселенную Гарри: на пир в школу чародейства и волшебства, уютный обед у миссис Уизли или чаепитие у Хагрида.
                        </div>
                        <div className={css.basketHiddenBlock} />
                        <BasketSetsSelectionSlider setName={setName} />
                    </div>
                </div>
            </div>
        </button>
    );
};

export default BasketSetsSelection;
