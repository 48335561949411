import React, { FC } from 'react';
import BasketCategoriesList from './components/BasketCategoriesList';
import BasketUpsellSelections from './components/BasketUpsellSelections';
// import BasketGreenSelection from './components/BasketGreenSelection';
// import BasketHalloweenSelection from './components/BasketHalloweenSelection';

import css from './BasketNewUpsell.module.scss';
import BasketSetsSelection from './components/BasketSetsSelection';

const BasketNewUpsell: FC = () => (
    <div className={css.basketNewUpsellRoot}>
        <div className={css.basketUpsellTitle}>Добавить что-то</div>
        <BasketCategoriesList />
        <BasketUpsellSelections />
        <BasketSetsSelection setName="hp" />
        {/* <BasketHalloweenSelection /> */}
        {/* <BasketGreenSelection /> */}
    </div>
);

export default BasketNewUpsell;
