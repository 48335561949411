import { PrinterLink } from 'apollo-link-printer';
import { errorLink } from './errorLink';
import { retryLink } from './retryLink';
import { operationMapLink } from './operationMapLink';
import { authLink } from './authLink';
import { httpLink } from './httpLink';
import { userInterceptors } from './userInterceptors';


const printerLink = new PrinterLink({
    collapsed: true,
    print: false,
});

export const linksAndInterceptorsChain = [
    printerLink,
    userInterceptors, errorLink, retryLink, operationMapLink, authLink, httpLink,
];
