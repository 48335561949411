/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { errorService, analyticService } from 'global/services';

import {
    LOCATION_MSK,
    LOCATION_NIZ,
    LOCATION_SPB,
    NIZ_CODES,
    SPB_CODES,
    KZN_CODES,
    LOCATION_KZN,
} from 'app/const/location';
import { FETCH_DELIVERY_ADDRESS_SESSION } from 'app/graphql/network/deliveryAddress.query';
import FETCH_AVAILABLE_MENU_DATES from 'app/graphql/network/menuDates';
import { setPaymentMethodReaction } from 'app/apollo/reactions/payment';
import { setSubscriptionTypeReaction } from 'app/apollo/reactions/subscription';
import { MUTATE_SHIPPING_ADDRESS } from './graphql/locationProvider.graphql';

const resolveLocation = (isoCode) => {
    if (SPB_CODES.includes(isoCode)) return LOCATION_SPB;
    if (NIZ_CODES.includes(isoCode)) return LOCATION_NIZ;
    if (KZN_CODES.includes(isoCode)) return LOCATION_KZN;
    return LOCATION_MSK;
};


const DEFAULT_LOCATION_CONTEXT = {
    locationKey: LOCATION_MSK,
    country_subdivision: 'RU-MOS',
    // eslint-disable-next-line no-unused-vars
    mutateAddreessHandler: (I) => { },
    // eslint-disable-next-line no-unused-vars
    getCityTitile: (I) => { },
    isLocationDataLoading: false,
};

export const LocationContext = createContext(DEFAULT_LOCATION_CONTEXT);

const resolveSubdivisionField = (addressValue) => {
    if (addressValue === 'г Москва') {
        return 'RU-MOS';
    }

    if (addressValue === 'г Санкт-Петербург') {
        return 'RU-SPE';
    }

    if (addressValue === 'г Нижний Новгород') {
        return 'RU-NIZ';
    }

    if (addressValue === 'г Казань') {
        return 'RU-TA';
    }

    return 'RU-MOS';
};

// RU-NIZ


export const withLocationContext = (Component) => (props) => (
    <LocationContext.Consumer>
        {(locationContext) => <Component {...props} locationContext={locationContext} />}
    </LocationContext.Consumer>
);

const getCityTitile = (deliveryAddressData) => {
    const initialCityObj = {
        title: 'Москва', longTitle: 'Москва', isMsk: true, isSpb: false, isNiz: false, isKzn: false,
    };

    try {
        const { deliveryAddress: { address: { country_subdivision: subdivision } } } = deliveryAddressData;

        if (['RU-MOS', 'RU-MOW'].includes(subdivision)) {
            return initialCityObj;
        }

        if (['RU-SPE', 'RU-LEN'].includes(subdivision)) {
            return {
                title: 'С-Петербург', longTitle: 'Cанкт-Петербург', isMsk: false, isSpb: true, isNiz: false, isKzn: false,
            };
        }

        if (['RU-NIZ', 'RU-NIO'].includes(subdivision)) {
            return {
                title: 'Н.Новгород', longTitle: 'Нижний Новгород', isMsk: false, isSpb: false, isNiz: true, isKzn: false,
            };
        }

        if (['RU-TA', 'RU-TAO'].includes(subdivision)) {
            return {
                title: 'Казань', longTitle: 'Казань', isMsk: false, isSpb: false, isNiz: false, isKzn: true,
            };
        }

        return initialCityObj;
    } catch (error) {
        errorService.log({
            source: 'client',
            text: 'Error getCityTitile in LocationProvider',
            scope: 'LocationProvider.js',
            error,
        });
        return initialCityObj;
    }
};

function LocationProvider(props) {
    const {
        children,
    } = props;

    const [locationState, setLocationState] = useState(DEFAULT_LOCATION_CONTEXT);
    const [isEventPush, setIsEventPush] = useState(false);

    const { loading: deliveryAddressLoading, data: addressData } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION, {
        onCompleted({ deliveryAddress }) {
            const { address: { country_subdivision: subdivision } } = deliveryAddress;

            if (!isEventPush) {
                analyticService.push({
                    eventName: 'Select_Region',
                    result: resolveLocation(subdivision),
                    source_name: 'default',
                });
                setIsEventPush(true);
            }
            // TODO: MSK BR1039 Тут можно замокать спб чтобы увидить быстро карту
            setLocationState({
                locationKey: resolveLocation(subdivision),
                country_subdivision: subdivision,
                isLocationDataLoading: false,

            });
        },
        context: {
            message: 'root:init:LocationProvider',
        },
        onError(error) {
            errorService.log({
                source: 'server',
                text: 'Error FETCH_DELIVERY_ADDRESS_SESSION in LocationProvider',
                scope: 'LocationProvider.js',
                error,
            });
        },
    });


    // useEffect(() => {}, )

    const [
        mutateAddreess,
        { loading: deliveryAddressMutationLoading },
    ] = useMutation(MUTATE_SHIPPING_ADDRESS, {
        onCompleted: ({ shipping }) => {
            const { address: { country_subdivision } } = shipping;

            if (locationState.country_subdivision !== country_subdivision) {
                /**
                 * @description set initial payment method
                 */
                setPaymentMethodReaction({ paymentMethod: 'online' });
                setSubscriptionTypeReaction({ type: 'subscription' });
            }
            setLocationState({
                ...locationState,
                country_subdivision,
            });
        },
        optimisticResponse: ({ address }) => {
            const { address: addressValue } = address;
            resolveSubdivisionField(addressValue);

            return {
                shipping: {
                    address: {
                        __typename: 'address',
                        country_subdivision: resolveSubdivisionField(addressValue),
                        city: addressValue,
                        id: null,
                        type: 'session_address',
                        beltway_distance: 0,
                        district: null,
                        street: null,
                        house: '',
                        entrance: null,
                        floor: null,
                        flat: null,
                        comment: '',
                        coordinates: {
                            latitude: 0,
                            longitude: 0,
                            __typename: 'addressCoordinates',
                        },
                    },
                },
            };
        },

        onError: (error) => {
            errorService.log({
                source: 'server',
                text: 'Error mutateAdreess in LocationProvider',
                scope: 'LocationProvider.js',
                error,
            });
        },

        refetchQueries: [
            {
                query: FETCH_AVAILABLE_MENU_DATES,
                context: {
                    message: 'root:refetch:LocationProvider',
                },
            },
        ],

        context: {
            message: 'root:update:LocationProvider',
        },
    });

    const mutateAddreessHandler = useCallback(
        async (payload, onCompleteAction = () => { }) => {
            try {
                mutateAddreess({
                    variables: { address: { ...payload } },
                    onCompleted: onCompleteAction(),
                });
            } catch (error) {
                errorService.log({
                    source: 'client',
                    text: 'Error mutateAddreessHandler in LocationProvider',
                    scope: 'LocationProvider.js',
                    error,
                });
            }
        }, [mutateAddreess]);

    const isDataLoading = useMemo(
        () => (deliveryAddressLoading || deliveryAddressMutationLoading),
        [deliveryAddressLoading, deliveryAddressMutationLoading],
    );


    const currentSubdivision = useMemo(
        () => addressData?.deliveryAddress?.address?.country_subdivision || 'RU-MOS',
        [addressData?.deliveryAddress?.address?.country_subdivision],
    );

    useEffect(
        () => {
            setLocationState({
                ...locationState,
                locationKey: resolveLocation(currentSubdivision),
            });
        },
        [currentSubdivision],
    );

    const contextValue = useMemo(() => ({
        ...locationState,
        mutateAddreessHandler,
        getCityTitile,
        isLocationDataLoading: isDataLoading,
    }), [locationState, mutateAddreessHandler, isDataLoading]);

    return (
        <LocationContext.Provider value={contextValue}>
            {children}
        </LocationContext.Provider>
    );
}

export default React.memo(LocationProvider);


LocationProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
