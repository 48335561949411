import React, {
    useEffect,
    useRef,
    useContext,
    Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Velocity from 'velocity-animate';
import compose from 'lodash/flowRight';
import { periods as nyPeriods } from 'global/const/ny';

import withAvailableDeliveryDates from 'app/containers/withAvailableDeliveryDates';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { analyticService } from 'global/services';

import { formatPeriodMobile, formatDateToISO } from 'app/utils/date';
import { usePrevious } from 'app/utils/hooks';

import Checkbox, { TYPE_RADIO } from 'app/components/Form/Checkbox2';

import LINKS from 'app/const/links';

import { DIFFS } from 'app/containers/contexts/menuDates.consts';

import './period-list.scss';


const { EVERYDAY_SET } = LINKS;

const ANIMATION_OPTIONS = {
    duration: 400,
    easing: 'ease-in-out',
};

function animateExpand(el) {
    Velocity(
        el,
        'slideDown',
        ANIMATION_OPTIONS,
    );
}

function animateCollapse(el, options = {}) {
    Velocity(
        el,
        'slideUp',
        {
            ...ANIMATION_OPTIONS,
            ...options,
        },
    );
}


const defaultTomorrowDeliveryDate = { dateObj: null };


function PeriodList({
    periods,
    selectedPeriod,
    onSelect,
    isExpanded = false,
    locale,

    tomorrowDeliveryDate = defaultTomorrowDeliveryDate,
    deliveryDatesEveryday,
    deliveryDatesEE,
    deliveryDates,
}) {
    const containerRef = useRef();
    const prevIsExanded = usePrevious(isExpanded);

    const {
        onSelectDate,
        state: {
            currentDate,
            dateFilterStatus,
            filterPeriod,
        },
    } = useContext(menuDatesState);

    useEffect(() => {
        const el = containerRef.current;

        if (prevIsExanded === undefined && el) {
            animateCollapse(el, { duration: 0 });
            return;
        }

        const isExpandStateChanged = isExpanded !== prevIsExanded;

        if (!isExpandStateChanged || !el) return;

        if (isExpanded) {
            animateExpand(el);
        } else {
            animateCollapse(el);
        }
    });

    /** @description неделя */
    const periodHandler = (periodStart, e) => {
        e.stopPropagation();

        const isCurrentPeriod = filterPeriod === periodStart;
        const [firstEEdate] = deliveryDatesEE;
        const firstEEdateISO = firstEEdate && formatDateToISO(firstEEdate.dateObj);

        const dateForSelect = (isCurrentPeriod && firstEEdate) ? firstEEdateISO : periodStart;

        const epochPeriodStart = new Date(dateForSelect).getTime() / 1000;
        const dayDiff = epochPeriodStart - (currentDate / 1000);

        onSelectDate(dayDiff, dateForSelect);
        onSelect(periodStart);
    };

    /** @description день  */
    const selectDeliveryDateHandler = (e) => {
        e.stopPropagation();

        const dayDiff = e.currentTarget.name;

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'day',
            action: 'Button',
            index: DIFFS.indexOf(dayDiff),
        });

        onSelectDate(dayDiff, filterPeriod);
        onSelect(filterPeriod);
    };

    const futurePeriods = periods
        .filter((p) => !nyPeriods.includes(p.start))
        .filter((p) => p.start !== filterPeriod);

    // const isMagicPeriodEnabled = periods.find((p) => p.start === '2021-12-27');
    const isMagicPeriodEnabled = false;
    const deliveryDatesForRender = isMagicPeriodEnabled ? deliveryDates : deliveryDatesEE;

    const firstEverydayDate = deliveryDatesEveryday[0];
    const firstEverydayDateISO = firstEverydayDate ? formatDateToISO(deliveryDatesEveryday[0].dateObj) : null;
    const tomorrowISO = tomorrowDeliveryDate ? formatDateToISO(tomorrowDeliveryDate.dateObj) : null;
    const isEverydayMenuAvailableForTomorrow = firstEverydayDateISO === tomorrowISO;

    return (
        <div styleName="period-list" ref={containerRef}>
            {isEverydayMenuAvailableForTomorrow && (
                <li styleName="period-list__item" key={tomorrowDeliveryDate.xValue}>
                    <a
                        styleName="period-list__item-button"
                        href={EVERYDAY_SET.href}
                        name={tomorrowDeliveryDate.xValue}
                        data-test-id="tomorrow__desktop"
                    >
                        <Checkbox
                            type={TYPE_RADIO}
                            name={tomorrowDeliveryDate.xValue}
                            id={tomorrowDeliveryDate.xValue}
                            checked={tomorrowDeliveryDate.isChecked}
                            onClick={() => { }}
                            onChange={(e) => e.stopPropagation()}
                        />

                        <div styleName="period-text">
                            <strong>
                                {tomorrowDeliveryDate.day}
                                .
                                &nbsp;
                            </strong>
                            <span styleName="period-day">{tomorrowDeliveryDate.date}</span>
                            &nbsp;
                            {tomorrowDeliveryDate.month}
                        </div>
                    </a>
                </li>
            )}
            {deliveryDatesForRender.map(({
                xValue,
                day,
                date,
                month,
            }) => {
                const isFilterPeriodSelected = filterPeriod === selectedPeriod;
                const isChecked = (dateFilterStatus === xValue)
                    && isFilterPeriodSelected;

                if (xValue === tomorrowDeliveryDate.xValue) {
                    return null;
                }

                return (
                    <li styleName="period-list__item" key={xValue}>
                        <button
                            styleName="period-list__item-button"
                            onClick={selectDeliveryDateHandler}
                            type="button"
                            name={xValue}
                            data-test-id="x+day__desktop"
                        >
                            <Checkbox
                                type={TYPE_RADIO}
                                name={xValue}
                                id={xValue}
                                checked={isChecked}
                                onClick={selectDeliveryDateHandler}
                                onChange={(e) => e.stopPropagation()}
                            />

                            <div styleName="period-text">
                                <strong>
                                    {day}
                                    .
                                    &nbsp;
                                </strong>
                                <span styleName="period-day">{date}</span>
                                &nbsp;
                                {month}
                            </div>
                        </button>
                    </li>
                );
            })}

            {futurePeriods.map((period) => {
                const isSelected = period.start === selectedPeriod;
                const { start, end } = formatPeriodMobile(period, selectedPeriod);

                return (
                    <Fragment key={`${period.start}-${period.end}`}>
                        {!nyPeriods.includes(period.start) && (
                            <li styleName="period-list-item" key={`${period.start}-${period.end}`}>
                                <button
                                    type="button"
                                    styleName="period-list__item-button"
                                    onClick={(e) => {
                                        periodHandler(period.start, e);
                                    }}
                                    data-test-id="period__desktop"
                                >
                                    <Checkbox
                                        type={TYPE_RADIO}
                                        name="period"
                                        id={`period-${period.start}`}
                                        checked={isSelected}
                                        onClick={(e) => periodHandler(period.start, e)}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                    <div styleName="period-text">
                                        {(period.title) ? (
                                            period.title[locale]
                                        ) : (
                                            <>
                                                <span styleName="period-day">{start.day}</span>
                                                &nbsp;
                                                {start.month}
                                                &nbsp;-&nbsp;
                                                <span styleName="period-day">{end.day}</span>
                                                &nbsp;
                                                {end.month}
                                            </>
                                        )}
                                    </div>
                                </button>
                            </li>
                        )}
                        {nyPeriods.includes(period.start) && (
                            <li styleName="period-list-item" key="period-NY">
                                <a
                                    href="/blyuda-dlya-novogodnego-stola/"
                                    target="_blank"
                                    styleName="period-list__item-button"
                                >
                                    <Checkbox
                                        type={TYPE_RADIO}
                                        name="period"
                                        id="period-NY"
                                        checked={false}
                                        onClick={() => { }}
                                        onChange={() => { }}
                                    />
                                    <div styleName="period-text">
                                        На новый год
                                    </div>
                                </a>
                            </li>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
}

export default compose(
    withAvailableDeliveryDates,
)(PeriodList);

PeriodList.propTypes = {
    periods: PropTypes.arrayOf(PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
    })).isRequired,
    selectedPeriod: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool,
    deliveryDatesEveryday: PropTypes.arrayOf(PropTypes.shape({
        dateObj: PropTypes.shape({}), // new Date()
        xValue: PropTypes.string,
        isChecked: PropTypes.bool,
    })).isRequired,
    tomorrowDeliveryDate: PropTypes.shape({
        dateObj: PropTypes.shape({}), // new Date()
        xValue: PropTypes.string,
        isChecked: PropTypes.bool,
    }),

};
