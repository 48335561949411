import flatten from 'lodash/flatten';
import every from 'lodash/every';

import { NY_DESSERT, NY_DISHES } from 'app/utils/filters';


export const periods = [
    '2024-12-23',
    '2024-12-30',
];

export const extraPeriodCustomStart = '2024-12-23';
export const extraPeriodCustomEnd = '2024-12-26';

export const extraPeriodNYStart = '2024-12-27';
export const extraPeriodNYEnd = '2024-12-31';


export const enabledDishes = {
    // наборы на черном фоне
    set: [
        '4702',
        '4703',
        '4704',
        '4705',
    ],
    // остальное на белом фоне
    salads: [
        '3839',
        '57',
        '4701',
        '4700',
        '4699',
        '4208',
    ],
    main: [
        '2750',
        '4697',
        '4698',
        '2751',
    ],
    dessert: [
        '1257',
        '3834',
        '2744',
    ],
    niceStuff: [
        '3433',
        '9',
        '172',
        '364',
        '1258',
        '3386',
        '2561',
    ],
    gifts: [
        '3889',
        '3890',
    ],
    delicacies: [],
};

export const dessertHP = [

];

export const nyMenuDishWithLabel = [

];

export const availableBasketDishIds = flatten(Object.values(enabledDishes));


/**
 * @param {string[]} basketDishesIds - An array of dish ID in the basket.
 * @return {string[]} An array of NY invoice tags.
 */
export function getInvoiceTags(basketDishesIds) {
    const isEveryDishIsDessert = every(
        basketDishesIds,
        (basketDishId) => enabledDishes.dessert.includes(basketDishId),
    );
    const isEveryDishIsNotDessert = every(
        basketDishesIds,
        (basketDishId) => !enabledDishes.dessert.includes(basketDishId),
    );
    if (isEveryDishIsDessert) {
        return [NY_DESSERT];
    }
    if (isEveryDishIsNotDessert) {
        return [NY_DISHES];
    }
    return [NY_DISHES, NY_DESSERT];
}
