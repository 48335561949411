/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { useQuery } from '@apollo/client';
import Raven from 'raven-js';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';
import { analyticService } from 'global/services';
import { useAddToBasketMutation } from 'app/views/Menu/Menu/hooks';

import {
    GET_SUBSCRIPTION_TYPE,
} from 'app/graphql/client/subscription';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';

import { isSubscription } from 'app/const/subscription';
import { MOBILE_ANIMATION_PARAMS } from 'app/const/menuAnimation';
import { CATEGORIES_ORDER } from 'app/const/categories';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { MenuDispatchContext } from 'app/containers/contexts/menu.context';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';

import MobileCartHeader from 'app/components/MobileCartHeader';
import OpenBasketButton from 'app/components/OpenBasketButton';
import Dishes from 'app/components/Dishes';
import { UIBanner, UIParagraph } from 'app/components/ui';

import {
    selectDishes,
} from 'app/utils/dish';

import { getBannerContent } from './dishesSelection.record';

import './dishes-selection.scss';


const SelectionPopOverBanner = ({ tag, realm = '' }) => {
    const bannerContent = getBannerContent(tag);
    const {
        title, backgroundImagePath, description, bgSize,
    } = bannerContent;
    const bannerDescriptionStyles = classNames({
        'selection-poppver-banner-description': true,
        [bgSize]: true,
    });

    const isNewUpsellSelection = realm === 'new_upsell';

    return (
        <div styleName={`selection-popover-banner-wrapper ${isNewUpsellSelection && 'category-selection'}`}>
            {!backgroundImagePath && (
                <p styleName="selection-poppver-banner-title black centered">
                    {title}
                </p>
            )}
            {backgroundImagePath && (
                <UIBanner bannerSize="m" backgroundImage={backgroundImagePath} bgSize={bgSize}>
                    <p styleName="selection-poppver-banner-title">
                        {title}
                    </p>
                </UIBanner>
            )}
            {description && (
                <UIParagraph level="3.1" styleName={bannerDescriptionStyles}>
                    {description}
                </UIParagraph>
            )}
        </div>
    );
};
SelectionPopOverBanner.propTypes = {
    tag: PropTypes.string.isRequired,
    realm: PropTypes.string,
};

const DishesSelectionButton = ({
    // ACTION
    onClickOpenBasket, onCloseSelection,
    // DATA
    price, loading, realm = '',
}) => {
    const realmsToCloseBasket = ['basket_realm', 'new_upsell'];

    const onClick = realmsToCloseBasket.includes(realm)
        ? onCloseSelection
        : onClickOpenBasket;
    return (
        <OpenBasketButton
            price={price}
            onClick={onClick}
            loading={loading}
        />
    );
};
DishesSelectionButton.propTypes = {
    onClickOpenBasket: PropTypes.func.isRequired,
    onCloseSelection: PropTypes.func.isRequired,
    price: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    realm: PropTypes.string.isRequired,
};

function DishesSelection({
    // data,
    overlayIsOpen,
    menuDishesQuery: {
        allDishes,
        basketDishes,
    },
    basketQuery: {
        cart: {
            totals: {
                total_discount_price: price,
            },
        },
        refetch: refetchBasket,
    },
    location = window?.location,
}) {
    const contentRef = useRef();
    const isSelectionFromBasket = window.location.pathname.includes('basket');

    const { locale } = useContext(LocaleContext);
    const menuDispatchContext = useContext(MenuDispatchContext);
    const { data, onCloseSelection } = useContext(DishesSelectionContext);

    const { data: { subscriptionType } } = useQuery(GET_SUBSCRIPTION_TYPE);
    const { selectedPeriod } = useSelectedFilters();

    const { tag, realm } = data;

    const [isBasketLoading, setIsBasketLoading] = useState(false);
    const [savedTag, setSavedTag] = useState(tag);
    const { handleAddToBasketMutation } = useAddToBasketMutation();

    useEffect(() => {
        if (tag && tag !== savedTag) {
            setSavedTag(tag);
        }
    }, [tag]);

    useEffect(() => {
        if (data.isOpen) {
            analyticService.push({
                eventName: ' Open_Pop_Over',
                eventLabel: getBannerContent(tag).type,
            });
        }
    }, [data.isOpen]);

    const bannerContent = getBannerContent(tag);
    const configDishes = bannerContent && bannerContent.dishes ? bannerContent.dishes : [];


    const onClickAddToBasket = async (dishData) => {
        const { dishId, portion } = dishData;

        setIsBasketLoading(true);

        const variables = {
            period: selectedPeriod,
            dish_id: dishId,
            portions: portion,
        };

        try {
            handleAddToBasketMutation({ variables });
            const { data: { cart } } = await refetchBasket();

            const dishesDataList = [dishData];

            analyticService.push({
                eventName: 'add_to_cart',
                dishes: dishesDataList,
                source: isSelectionFromBasket ? 'upsell' : 'collections',
                subscriptionType,
                typeOfSet: cart.typeOfSet,
            });

            dishesDataList.forEach((dish) => analyticService.push({
                eventName: 'add_to_cart_fb',
                dish,
            }));
        } catch (e) {
            Raven.captureException(e);
        }

        setIsBasketLoading(false);
    };

    const isSubscriptionActivated = isSubscription(subscriptionType);

    const hasConfigDishes = configDishes && configDishes.length > 0;

    const hasCategory = Boolean(bannerContent?.categoryId);
    const isFilterByTagNeeded = !(configDishes && configDishes.length > 0) && !hasCategory;
    const isFilterByCategoryNeeded = hasCategory;

    const filterTags = isFilterByTagNeeded ? [savedTag] : [];
    const filterCategory = isFilterByCategoryNeeded ? bannerContent?.categoryId : null;

    const dishes = selectDishes(allDishes, filterCategory, filterTags, null, basketDishes)
        .filter((dish) => {
            if (!hasConfigDishes) return true;
            const isDishFromConfig = configDishes.includes(dish.id);
            const isDishWithTag = bannerContent.additionalTag
                ? dish.tags.find((t) => t.id === bannerContent.additionalTag)
                : false;

            return isDishFromConfig || isDishWithTag;
        });

    const sortDishesByConfigOrder = () => {
        const dishesFromConfig = configDishes.map((id) => {
            const configDish = dishes.find((d) => d.id === id);
            return configDish;
        }).filter((dish) => Boolean(dish));

        const restDishes = dishes.filter((dish) => {
            const isRest = !configDishes.includes(dish.id);
            return isRest;
        });

        return [
            ...dishesFromConfig,
            ...restDishes,
        ];
    };

    const sortedDishes = hasConfigDishes
        ? sortDishesByConfigOrder()
        : sortBy(
            dishes,
            CATEGORIES_ORDER
                .map((categoryId) => (dish) => String(dish.categoryId) === categoryId)
                .reverse(),
        );

    const menuDishesQuery = {
        menuDishes: sortedDishes,
        loading: false,
        error: null,
    };
    // TODO Удалить когда закончиться hygge
    // const sortedMenuDishesQuery = hyggeSortingKostyl(menuDishesQuery, tag, configDishes);

    return (
        <Transition
            in={data.isOpen}
            timeout={500}
            appear
        >
            {(transitionState) => (
                <>
                    {!overlayIsOpen && data.isOpen && contentRef.current && (
                        <ScrollLock touchScrollTarget={contentRef.current} />
                    )}
                    <div styleName={`dishes-selection__wrap ${transitionState} ${realm}`}>
                        {transitionState !== 'exited' && (
                            <>
                                <div styleName="dishes-selection__header">
                                    <MobileCartHeader
                                        isRightBlockShown={false}
                                        onClickBack={onCloseSelection}
                                        onClickClose={onCloseSelection}
                                        realm="selection"
                                    />
                                </div>

                                <div styleName="dishes-selection__content" ref={contentRef}>
                                    <SelectionPopOverBanner tag={savedTag} realm={realm} />
                                    <div styleName="dishes-selection__list">
                                        <Dishes
                                            categoryTitle=""
                                            delayTime={100}
                                            isDishesUpdating={false}
                                            locale={locale}
                                            isBasketLoading={isBasketLoading}
                                            menuDispatchContext={menuDispatchContext}
                                            menuDishesQuery={menuDishesQuery}
                                            categoryId="selection"
                                            isMobileMenuDishesVisible
                                            isSubscriptionActivated={isSubscriptionActivated}
                                            addToBasketAnimationTarget={MOBILE_ANIMATION_PARAMS}
                                            onClickAddToBasket={onClickAddToBasket}
                                            isDishesSliderNeeded={false}
                                            isAutoscrollingDisabled
                                            subscriptionType={subscriptionType}
                                            location={location}
                                            dishComponentType="mobile"
                                        />
                                    </div>
                                </div>

                                <div styleName="dishes-selection__footer">
                                    <DishesSelectionButton
                                        price={price}
                                        onClickOpenBasket={onCloseSelection}
                                        loading={isBasketLoading}
                                        location={location}
                                        realm={realm}
                                        onCloseSelection={onCloseSelection}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <button
                        styleName={`dishes-selection-close-layer  ${transitionState}`}
                        type="button"
                        onClick={onCloseSelection}
                    />
                </>
            )}
        </Transition>
    );
}


export default DishesSelection;


DishesSelection.propTypes = {
    overlayIsOpen: PropTypes.bool.isRequired,
    // data
    menuDishesQuery: PropTypes.shape({
        allDishes: PropTypes.arrayOf(PropTypes.shape({})),
        basketDishes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    location: PropTypes.shape({}),

    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            totals: PropTypes.shape({
                total_discount_price: PropTypes.number,
            }),
        }),
        refetch: PropTypes.func.isRequired,
    }).isRequired,

    // callbacks
    onClickOpenBasket: PropTypes.func.isRequired,
};
