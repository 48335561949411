import React from 'react';
import PropTypes from 'prop-types';

import StreetInputMobile from 'app/components/Form/StreetInputMobile';
import LoadingBigIcon from 'app/components/LoadingBigIcon';

import ArrowRight from './arrow.component.svg';

import './mobile-street.scss';

class MobileStreet extends React.Component {
    state = {
        inputValue: '',
        close: 'init',
    };

    isCloseCallbackCalled = false;

    inputRef = React.createRef();

    constructor(props) {
        super(props);
        const {
            info: { value: valueFromComponent },
        } = this.props;
        if (valueFromComponent) {
            this.state.inputValue = valueFromComponent;
        }
    }

    componentDidMount() {
        const { focusDelay } = this.props;
        this.isCloseCallbackCalled = false;

        if (this.inputRef.current) {
            if (focusDelay) {
                setTimeout(() => {
                    this.inputRef.current.focus();
                }, focusDelay);
            } else {
                this.inputRef.current.focus();
            }
        }

        // window.scrollTo(0, 0); // скроллим вверх для мобильного сафари
    }

    componentDidUpdate() {
        // window.scrollTo(0, 0); // скроллим вверх для мобильного сафари

        const { close } = this.state;
        const { info: { street, isStreetEditing } } = this.props;
        const streetIsValid = !!street.data.house && street.value && street.value.trim() !== '';
        if (streetIsValid && close === 'close' && isStreetEditing) {
            this.inputRef.current.blur();
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ close: 'closing' });
        }
        if (streetIsValid && close === 'closing' && !isStreetEditing) {
            if (!this.isCloseCallbackCalled) {
                this.handleClose();
            }
            this.isCloseCallbackCalled = true;
        }
    }

    componentWillUnmount() {
        this.inputRef.current.blur();
    }

    handleChangeStreet = (e) => {
        const { info: { onChange } } = this.props;
        const { target: { value } } = e;

        onChange(value);
        this.setState({ inputValue: value });
    };

    handleSelectStreet = (item) => {
        const { info: { onSelect, onChange } } = this.props;
        const { value } = item;

        const v = `${value} `;
        onChange(v);
        onSelect(item);
        this.setState({ inputValue: v, close: 'close' });
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    handleClose = async () => {
        const { info: { onBlur }, onClose } = this.props;
        if (onBlur) await onBlur();
        onClose();
    };

    render() {
        const { inputValue, close } = this.state;
        const {
            info: { suggestions },
            placeholder,
            hideDecoreations,
        } = this.props;

        const haveSuggestions = suggestions && suggestions.length >= 1;
        const isShowSuggestion = inputValue !== '' && haveSuggestions;

        const containerClassName = hideDecoreations ? '' : 'mobile-street';

        return (
            <div styleName={containerClassName}>
                {!hideDecoreations && (
                    <div styleName="mobile-street-header">
                        <button
                            type="button"
                            aria-label="Закрыть"
                            styleName="button-done"
                            onClick={this.handleClose}
                            data-test-id="button-done"
                        >
                            <ArrowRight />
                        </button>
                    </div>
                )}
                <div styleName="mobile-street__input">
                    <StreetInputMobile
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={this.handleChangeStreet}
                        inputRef={this.inputRef}
                    />
                </div>
                {close === 'closing' && (
                    <div styleName="loading--container"><LoadingBigIcon /></div>
                )}
                <div
                    styleName="mobile-street__scroll"
                    // id="touch-scrolllock-select" note: deprecated
                >
                    {(close !== 'closing' && isShowSuggestion) && (
                        <div styleName="mobile-street__street-list">
                            {suggestions.map((suggestion) => {
                                const {
                                    data: {
                                        country,
                                        city,
                                        city_with_type: cityWithType,
                                        region,
                                        region_with_type: regionWithType,
                                    },
                                    value,
                                } = suggestion;

                                let address = value.replace(`${cityWithType}, `, '');
                                address = address.replace(`${regionWithType}, `, '');

                                let cityAddress = `${country}, ${regionWithType}`;
                                if (city !== null && region !== city) {
                                    cityAddress += `, ${city}`;
                                }

                                return (
                                    <button
                                        key={value}
                                        type="button"
                                        styleName="street-item"
                                        onClick={() => this.handleSelectStreet(suggestion)}
                                    >
                                        {address}
                                        <div styleName="street-item__footer">{cityAddress}</div>
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

MobileStreet.propTypes = {
    focusDelay: PropTypes.number,
    info: PropTypes.shape({
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        suggestions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        street: PropTypes.shape({
            data: PropTypes.shape({}),
            value: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    placeholder: PropTypes.string,
    hideDecoreations: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

const MobileStreetFC = (props) => {
    const {
        placeholder = null,
        focusDelay = null,
        hideDecoreations = false,
    } = props;
    return (
        <MobileStreet
            {...props}
            placeholder={placeholder}
            focusDelay={focusDelay}
            hideDecoreations={hideDecoreations}
        />
    );
};

export default MobileStreetFC;
